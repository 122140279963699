import { render, staticRenderFns } from "./reportcardDetail.vue?vue&type=template&id=ba475bb4&scoped=true"
import script from "./reportcardDetail.vue?vue&type=script&lang=js"
export * from "./reportcardDetail.vue?vue&type=script&lang=js"
import style0 from "./reportcardDetail.vue?vue&type=style&index=0&id=ba475bb4&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba475bb4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/gva_web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ba475bb4')) {
      api.createRecord('ba475bb4', component.options)
    } else {
      api.reload('ba475bb4', component.options)
    }
    module.hot.accept("./reportcardDetail.vue?vue&type=template&id=ba475bb4&scoped=true", function () {
      api.rerender('ba475bb4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/view/report/reportcard/reportcardDetail.vue"
export default component.exports