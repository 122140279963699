var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      display: "inline-block"
    }
  }, [_vm.reloadIframe ? _c("iframe", {
    ref: "customIframe",
    attrs: {
      id: "frmReport",
      width: "100%",
      src: _vm.url,
      scrolling: "no",
      allowfullscreen: "true",
      webkitallowfullscreen: "true",
      mozallowfullscreen: "true",
      oallowfullscreen: "true",
      msallowfullscreen: "true",
      frameborder: "0"
    }
  }) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };